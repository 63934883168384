/**
 * This module should be the first entrypoint in webpack config, since the browser should execute it before anything in the bundle.
 */
import 'core-js'; // Should be imported at the very top of an entry point. Will be optimized by `@babel/preset-env`.

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

// window.scroll options object support for Safari, IE, Edge
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
